import * as React from 'react';

export const ArrowDownRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 7l10 10m0-10v10H7"
      ></path>
    </svg>
  );
};

const MemoArrowDownRightIcon = React.memo(ArrowDownRightIcon);
export default MemoArrowDownRightIcon;
