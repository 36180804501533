export const Title = ({
  children,
  type,
  className = '',
}: {
  children: React.ReactNode;
  type: 'h1' | 'h2' | 'h3';
  className?: string;
}) => {
  return (
    <>
      {type === 'h1' && (
        <h1
          className={`text-[40px] font-semibold tracking-tight md:text-[60px] lg:text-[85px] 2xl:text-[95px] ${className}`}
        >
          {children}
        </h1>
      )}
      {type === 'h2' && (
        <h2 className={`text-[26px] font-semibold tracking-tight lg:text-[45px] 2xl:text-[50px] ${className}`}>
          {children}
        </h2>
      )}
      {type === 'h3' && (
        <h3 className={`text-[20px] font-semibold tracking-tight lg:text-[25px] ${className}`}>{children}</h3>
      )}
    </>
  );
};
