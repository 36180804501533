import { createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import { httpClient } from '../api';
import { NetworkExceptionError } from '../exceptions';
import { CategoriesResponse, Category, ShopItem } from './types';

const URL = process.env.NEXT_PUBLIC_API_ENDPOINT;

/**
 * Fetch the list of the available categories
 */
export const getAllCategoriesFx = createEffect<void, CategoriesResponse, NetworkExceptionError>(async () => {
  const allCategoriesResponse = await httpClient<CategoriesResponse>({
    url: `${URL}/api/categories?populate=*`,
    method: 'GET',
  });

  return allCategoriesResponse;
});

export const getAllCategoriesQuery = createQuery({
  handler: getAllCategoriesFx,
});

/**
 * Fetch specific category by slug
 */
export const getCategoryBySlugFx = createEffect<void, Category, NetworkExceptionError>(async (categorySlug) => {
  const specificCategoryResponse = await httpClient<CategoriesResponse>({
    url: `${URL}/api/categories?filters[slug]=${categorySlug}&populate=*`,
    method: 'GET',
  });

  return specificCategoryResponse.data[0];
});

export const getCategoryBySlugQuery = createQuery({
  handler: getCategoryBySlugFx,
});

/**
 * Fetch items from the certain category by slug
 */
export const getItemsByCategoryFx = createEffect<void, CategoriesResponse, NetworkExceptionError>(
  async (categorySlug) => {
    const categoryItemsResponse = await httpClient<CategoriesResponse>({
      url: `${URL}/api/items?filters[category][slug]=${categorySlug}&populate[size][populate]=numberFilter&populate[size][populate]=selectFilter&populate[image]=*`,
      method: 'GET',
    });

    return categoryItemsResponse;
  },
);

export const getItemsByCategoryQuery = createQuery({
  // name: 'getItemsByCategoryQuery',
  handler: getItemsByCategoryFx,
});
