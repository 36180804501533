import React from 'react';
import { useRouter } from 'next/router';

import { Footer } from '@/widgets/layouts/main/components/footer';
import { Header } from '@/widgets/layouts/main/components/header';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const router = useRouter();
  const isIndexPage = router.pathname === '/';

  return (
    <div className="flex min-h-screen flex-col">
      <Header className={isIndexPage ? 'bg-secondary' : ''} />

      <main className="flex-grow">{children}</main>

      <Footer />
    </div>
  );
};
