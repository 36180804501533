import { useState } from 'react';
import Link from 'next/link';
import { useUnit } from 'effector-react';

import { getAllCategoriesQuery } from '@/shared/api/catalog/api';
import { CartIcon } from '@/shared/components/icons/cart';
import MenuIcon from '@/shared/components/icons/menu';
import XIcon from '@/shared/components/icons/x-sign';
import { Button } from '@/shared/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/shared/components/ui/navigation-menu';
import { Wrapper } from '@/shared/components/wrapper';
import { cn } from '@/shared/lib/cn'; // Make sure to import cn from your utils

import { NAV_LINKS } from './constants';

interface HeaderProps {
  className?: string;
}

export const Header = ({ className }: HeaderProps) => {
  const { data: categories } = useUnit(getAllCategoriesQuery);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={cn('bg-secondary-foreground', className)}>
      <header className="block rounded-b-xl bg-[#DBE6F0] md:hidden">
        <Wrapper className="flex items-center justify-between px-5 py-4">
          <Link href="/" className="text-2xl font-bold capitalize">
            Азимут
          </Link>

          <button
            onClick={() => setIsMenuOpen(true)}
            className="rounded-md p-2 transition-colors hover:bg-gray-200"
            aria-label="Open menu"
          >
            <MenuIcon />
          </button>
        </Wrapper>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 z-[9999] bg-[#DBE6F0]">
          <Wrapper className="flex h-full flex-col items-end justify-between py-4">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="-translate-x-5 rounded-md p-2 transition-colors hover:bg-gray-200"
              aria-label="Close menu"
            >
              <XIcon />
            </button>

            <div className="mb-10 flex w-full flex-col items-end gap-8">
              <nav className="flex w-full flex-col items-end gap-8 text-xl">
                {NAV_LINKS.map((link) => (
                  <Button variant="ghost" key={link.href} asChild>
                    <Link href={link.href} className="underline" onClick={() => setIsMenuOpen(false)}>
                      {link.text}
                    </Link>
                  </Button>
                ))}
              </nav>

              <Button variant="ghost" asChild>
                <Link href="tel:+79095379940" className="text-xl underline">
                  +7 (909) 537 99 40
                </Link>
              </Button>
            </div>
          </Wrapper>
        </div>
      )}

      <header className="hidden pt-10 md:block">
        <Wrapper className="flex items-center justify-between rounded-xl bg-[#DBE6F0] px-5 py-3 lg:px-7 lg:py-5">
          <Link href="/" className="text-5xl font-bold capitalize">
            Азимут
          </Link>

          <div className="flex items-center gap-2">
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="p-5 text-xl">
                    <Link href="/">Главная</Link>
                  </NavigationMenuTrigger>

                  <NavigationMenuContent className="min-w-[250px] rounded-lg p-4">
                    <div className="group mb-4 flex flex-col gap-1 rounded-md p-2 transition hover:bg-muted">
                      <Link href="/#services" className="text-sm group-hover:underline">
                        Услуги
                      </Link>
                      <p className="text-xs text-gray-500">Услуги по доставке и проведению натурных испытаний</p>
                    </div>

                    <div className="group flex flex-col gap-1 rounded-md p-2 transition hover:bg-muted">
                      <Link href="/#contacts" className="text-sm group-hover:underline">
                        Контакты
                      </Link>
                      <p className="text-xs text-gray-500">Контакты для связи с нами и заказа услуг</p>
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>

                <NavigationMenuItem>
                  <NavigationMenuTrigger className="p-5 text-xl">
                    <Link href="/catalog">Каталог</Link>
                  </NavigationMenuTrigger>

                  <NavigationMenuContent className="min-w-[250px] gap-4 rounded-lg p-4">
                    {categories?.data.map((category) => (
                      <div key={category.attributes.slug} className="flex flex-col gap-2">
                        <Link
                          href={`/catalog/${category.attributes.slug}`}
                          className="rounded-md p-2 text-sm transition hover:bg-muted hover:underline"
                        >
                          {category.attributes.name}
                        </Link>
                      </div>
                    ))}
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>

            <Button variant="ghost" size="icon-lg" asChild>
              <Link href="/cart">
                <CartIcon className="h-6 w-6" />
              </Link>
            </Button>
          </div>

          <Button variant="ghost" asChild>
            <Link href="tel:+79095379940">+7 (909) 537 99 40</Link>
          </Button>
        </Wrapper>
      </header>
    </div>
  );
};
