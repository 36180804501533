import React from 'react';

import { cn } from '@/shared/lib/cn';

interface WrapperProps {
  children: React.ReactNode;
  className?: string;
  as?: React.ElementType;
}

export const Wrapper = (props: WrapperProps) => {
  const { children, className, as: Component = 'div' } = props;
  const wrapperClassName = cn(
    'mx-auto max-w-[324px] sm:max-w-[480px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1420px]',
    className,
  );

  return <Component className={wrapperClassName}>{children}</Component>;
};
